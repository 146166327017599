import { Button, Notification } from '@bitsoflove/pattyn-360/lib/components';
import Link from 'next/link';

import {
  STATE_LOADING,
  STATE_READY,
  STATE_CHECKING,
  STATE_REDIRECTING,
  useAuth,
  validateLogin,
  STATE_UNAUTHORIZED,
} from '~/api/AuthProvider';
import { AUTH_ROUTES } from '~/config/auth';
import AuthPageLayout from '~/containers/AuthLayout/Default';

const LABELS = {
  [STATE_LOADING]: 'Checking login status',
  [STATE_CHECKING]: 'Checking login',
  [STATE_REDIRECTING]: 'Redirecting',
};

function LoginPage() {
  const { state, error, login } = useAuth();
  const isUnauthorized = state === STATE_UNAUTHORIZED;
  const isReady = state === STATE_READY || isUnauthorized;

  return (
    <AuthPageLayout
      message={
        error && (
          <p>
            <Notification variant="negative" icon="notifications">
              Login failed, please check your credentials and retry.
            </Notification>
          </p>
        )
      }
      initialValues={{ email: '', password: '' }}
      onSubmit={login}
      validate={async values => {
        const result = await validateLogin(values, ['email', 'password']);
        return result.getErrors();
      }}
      link={
        <Link href={AUTH_ROUTES.requestPassword}>
          <a>Forgot password?</a>
        </Link>
      }
    >
      {formik => {
        return (
          <>
            <AuthPageLayout.Field
              formik={formik}
              name="email"
              type="email"
              label="E-mail"
              placeholder="ex. jan@pattyn.be"
            />
            <AuthPageLayout.Field
              formik={formik}
              name="password"
              type="password"
              label="Password"
              placeholder="******"
            />

            <Button
              className="login-button"
              variant="primary"
              type="submit"
              block
              disabled={!isReady || !formik.isValid}
              loading={!isReady}
            >
              {isReady ? 'Login' : LABELS[state]}
            </Button>
          </>
        );
      }}
    </AuthPageLayout>
  );
}

LoginPage.options = {
  isPublic: true,
  isAuth: true,
};

export default LoginPage;
