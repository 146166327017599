import decoration from '@bitsoflove/pattyn-360/lib/assets/images/login-decoration.svg';
import logo from '@bitsoflove/pattyn-360/lib/assets/images/pattyn-logo.svg';
import {
  AuthLayout,
  FormItem,
  Label,
  Input,
  Icon,
} from '@bitsoflove/pattyn-360/lib/components';
import { Field, Formik, ErrorMessage } from 'formik';
import _ from 'lodash';
import Link from 'next/link';
import { rgba } from 'polished';
import { func, node, shape, string } from 'prop-types';
import { useEffect } from 'react';
import { styled } from 'styletron-react';

import { STATE_LOADING, useAuth } from '~/api/AuthProvider';
import { AUTH_ROUTES } from '~/config/auth';

const ContentWrapper = styled('div', () => ({
  position: 'relative',
}));
const LoaderWrapper = styled('div', () => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: rgba('#eef2f6', 0.9),
}));

function AuthPageLayout({
  children,
  title,
  message,
  initialValues,
  onSubmit,
  validate,
  link,
}) {
  const { state, clear } = useAuth();
  const isLoading = state === STATE_LOADING;

  useEffect(clear, []);

  return (
    <div style={{ height: '100vh' }}>
      <AuthLayout
        title={
          <>
            Pattyn 360 <strong>CMS</strong>
          </>
        }
        subtitle="Login to continue"
        formTitle={title}
        logoImage={logo}
        decorationImage={decoration}
      >
        <ContentWrapper>
          {message}
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
          >
            {formik => {
              return (
                <form onSubmit={formik.handleSubmit}>{children(formik)}</form>
              );
            }}
          </Formik>
          <br />
          {link}

          {isLoading && (
            <LoaderWrapper>
              <Icon icon="progress" spin />
            </LoaderWrapper>
          )}
        </ContentWrapper>
      </AuthLayout>
    </div>
  );
}

const AuthPageField = ({ formik, name, type, placeholder, label }) => {
  return (
    <FormItem
      id={`account-${name}`}
      direction="column"
      hasError={!!_.get(formik.errors, name)}
    >
      <Label>{label}</Label>
      <Field as={Input} type={type} name={name} placeholder={placeholder} />
      <ErrorMessage name={name} />
    </FormItem>
  );
};

AuthPageLayout.Field = AuthPageField;

AuthPageLayout.Field.propTypes = {
  formik: shape({ errors: shape({}) }).isRequired,
  name: string.isRequired,
  placeholder: string.isRequired,
  label: string.isRequired,
  type: string.isRequired,
};

AuthPageLayout.propTypes = {
  title: string,
  children: func.isRequired,
  message: node,
  initialValues: shape({
    email: string,
    password: string,
  }),
  onSubmit: func.isRequired,
  validate: func.isRequired,
  link: node,
};
AuthPageLayout.defaultProps = {
  title: 'Login',
  message: null,
  initialValues: {},
  link: (
    <Link href={AUTH_ROUTES.login}>
      <a>Return to login</a>
    </Link>
  ),
};

export default AuthPageLayout;
